import {
  AppShell as MantineAppShell,
  Box,
  Burger,
  Header,
  MediaQuery,
  Text,
  Transition,
  useMantineTheme,
} from "@mantine/core";
import Router from "next/router";
import React, { ReactNode, useState } from "react";

import { PAGE_ROUTES } from "../../constants/Navigation";
import { MemoryCreatedSignal } from "../../contexts/Signals";
import AppNav from "../AppNav/AppNav";
import Footer from "../Footer/Footer";

export interface AppShellProps {
  children: ReactNode;
  pageName?: string;
  fullScreen?: boolean;
}

export default function AppShell({
  children,
  pageName,
  fullScreen,
}: AppShellProps) {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  // subscribe to memory update events
  MemoryCreatedSignal.useSubscription(() => {
    if (window.location.pathname === PAGE_ROUTES.MEMORIES) {
      // force a reload on the memories view
      Router.reload();
    } else {
      Router.push(PAGE_ROUTES.MEMORIES);
    }
  });

  return (
    <MantineAppShell
      layout="alt"
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        !fullScreen && (
          <>
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Transition
                mounted={opened}
                transition="fade"
                duration={250}
                timingFunction="ease"
              >
                {(styles) => (
                  <div style={{ ...styles, zIndex: "100" }}>
                    <AppNav
                      p="md"
                      hiddenBreakpoint="sm"
                      width={{ sm: 200 }}
                      onClick={() => setOpened((o) => !o)}
                    />
                  </div>
                )}
              </Transition>
            </MediaQuery>
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <AppNav p="md" hiddenBreakpoint="sm" width={{ sm: 200 }} />
            </MediaQuery>
          </>
        )
      }
      header={
        !fullScreen && (
          <>
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <Header
                height={60}
                p="md"
                sx={{
                  borderRadius: "0 0 0 5px",
                  background: "linear-gradient(to right, #c419ff, #7183e9)",
                  color: theme.colors.gray[0],
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Text fw={200} size="20px">
                    {pageName ?? ""}
                  </Text>
                </div>
              </Header>
            </MediaQuery>
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Header
                height={60}
                p="md"
                sx={{
                  borderRadius: "0 0 0 5px",
                  background: "linear-gradient(to right, #c419ff, #7183e9)",
                  color: theme.colors.gray[0],
                }}
                onClick={() => setOpened((o) => !o)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                    <Burger
                      opened={opened}
                      size="sm"
                      color={theme.colors.gray[0]}
                      mr="xl"
                    />
                  </MediaQuery>

                  <Text fw={200} size="20px">
                    {pageName ?? ""}
                  </Text>
                </div>
              </Header>
            </MediaQuery>
          </>
        )
      }
    >
      <Box sx={{ minHeight: "100vh " }}>{children}</Box>
      <Box
        mt="80px"
        sx={{
          width: "100% !important",
          maxWidth: "100% !important",
        }}
      >
        <Footer />
      </Box>
    </MantineAppShell>
  );
}
