import {
  Box,
  FileButton,
  Grid,
  Image,
  Progress,
  Select,
  Text,
  Textarea,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconAlertTriangle,
  IconEraser,
  IconNotebook,
  IconTrashX,
} from "@tabler/icons-react";
import React, { useContext, useEffect, useState } from "react";

import * as Api from "../../components/ApiClient/Api";
import useChildList from "../../components/hooks/useChildList";
import useMemory from "../../components/hooks/useMemory";
import AuthContext from "../../contexts/Auth";
import {
  MemoryDeletedSignal,
  MemoryUpdatedSignal,
} from "../../contexts/Signals";
import Button from "../Button/Button";
import DangerButton from "../Button/DangerButton";
import CalendarSelect from "../CalendarSelect/CalendarSelect";
import useAccountUsers from "../hooks/useAccountUsers";
import useCurrentUser from "../hooks/useCurrentUser";
import useFileUpload from "../hooks/useFileUpload";
import useMediaList from "../hooks/useMediaList";
import Modal from "../Modal/Modal";
import Spinner from "../Spinner/Spinner";

interface memoryEditProps {
  children: any;
  data?: any;
  isOpen?: boolean;
  allowEdit: boolean;
  onClose?: () => void;
}

function MemoryEdit({
  children,
  data,
  isOpen = false,
  allowEdit = false, // todo : support this
  onClose = null,
}: memoryEditProps) {
  const theme = useMantineTheme();
  const { accessToken } = useContext(AuthContext);

  const modalControl = useDisclosure(!!isOpen, {
    onClose: () => {
      closeModal();
    },
  });
  const [, { close: modalCloser }] = modalControl;

  const [accountUsers] = useAccountUsers();
  const [me] = useCurrentUser();
  const memoryUpdatedPublishSignal = MemoryUpdatedSignal.usePublish();
  // const memoryCreatedPublishSignal = MemoryCreatedSignal.usePublish();
  const memoryDeletedPublishSignal = MemoryDeletedSignal.usePublish();

  const [memory, setMemoryData, saveMemory, deleteMemory] = useMemory(data);
  const [mediaList, removeMedia, commitMediaRemovals, resetMediaRemovals] =
    useMediaList(data);
  const childList = useChildList();
  const [currentFile, setCurrentFile, upload, uploadStatus, exifData] =
    useFileUpload({});

  const [showDelete, setShowDelete] = useState(false);
  const [selectedChildName, setSelectedChildName] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [originalMessage, setOriginalMessage] = useState<string>(
    memory.Message
  );

  const popMessage = ({ message, color = "grape", title = "Nice!" }) => {
    notifications.show({
      title,
      message,
      color,
    });
  };

  useEffect(() => {
    // if we don't have the child list yet
    // or if this is an existing memory
    // don't set the child id
    if (!childList || memory?.ChildID) {
      return;
    }
    // initialize the child id to the first child in the list
    setMemoryData({
      ...memory,
      ChildID: childList?.[0]?.ID,
    });
  }, [childList, open]);

  useEffect(() => {
    // on new efix data update the memory timestamp
    const exifDateTime = exifData?.tags?.DateTimeOriginal;
    if (exifDateTime) {
      setMemoryData({
        ...memory,
        Timestamp: new Date(exifDateTime * 1000).toISOString(),
      });
    }
  }, [exifData]);

  useEffect(() => {
    if (memory?.ChildID) {
      const childName = childList?.find(
        (c) => c.ID === memory.ChildID
      )?.FirstName;
      setSelectedChildName(childName);
    }
  }, [memory]);

  const closeModal = () => {
    setCurrentFile(undefined);
    resetMediaRemovals();
    setShowDelete(false);
    onClose && onClose();
  };

  const saveMemoryData = async () => {
    setIsSaving(true);
    try {
      const updatedMemoryData = await saveMemory();
      await commitMediaRemovals();
      await upload(updatedMemoryData.ID);

      popMessage({ message: "Memory saved!" });
      modalCloser();
      memoryUpdatedPublishSignal(updatedMemoryData);
    } catch (e) {
      popMessage({ message: "Error saving memory", color: "red" });
    }
    setIsSaving(false);
  };

  const deleteMemoryData = async () => {
    await deleteMemory();
    modalCloser();
    memoryDeletedPublishSignal(true);
  };

  const onSelectedChildUpdate = async (newChildId) => {
    setMemoryData({
      ...memory,
      ChildID: newChildId,
    });
  };

  const onTimestampChange = (newDate: Date) => {
    setMemoryData({
      ...memory,
      Timestamp: newDate.toISOString(),
    });
  };

  const onMessageChange = (e) => {
    setOriginalMessage(e.target.value);
    setMemoryData({
      ...memory,
      Message: e.target.value,
    });
  };

  const getAuthor = () => {
    const nameFromSenderId = accountUsers.find(
      (u) => u.ID === memory?.SenderUserID
    )?.FirstName;

    if (nameFromSenderId) {
      return nameFromSenderId;
    }
    return me?.FirstName;
  };

  const getSubject = () => {
    const childName = childList?.find(
      (c) => c.ID === memory.ChildID
    )?.FirstName;
    return childName;
  };

  const [isEnhancing, setIsEnhancing] = useState(false);
  const getMessageWithEmoji = async () => {
    setIsEnhancing(true);
    const { UpdatedMessage: updatedMessage } = await Api.postEmoji({
      memoryId: memory.ID,
      message: originalMessage,
      author: getAuthor(),
      subject: getSubject(),
      accessToken,
    });
    setMemoryData({
      ...memory,
      Message: updatedMessage,
    });
    setIsEnhancing(false);
  };

  // TODO" Move out to separate file
  const mediaElements = mediaList?.map((m) => {
    if (m.Deleted) {
      return;
    }
    const mediaPreview = (() => {
      if (m.ContentType.startsWith("image/")) {
        return <Image fit="contain" maw={40} mah={40} src={m.URL} />;
      } else if (m.ContentType.startsWith("video/")) {
        if (m.ContentType === "video/3gpp") {
          return (
            <Box
              sx={{ textAlign: "center", color: theme.colors.gray[5] }}
              key={m.ID}
            >
              <Text variant="caption">
                videos uploaded from SMS are not supported
              </Text>
            </Box>
          );
        }
        return <Image fit="contain" maw={40} mah={40} src={m.ThumbURL} />;
      }
    })();
    return (
      <Box key={m.ID}>
        <Grid>
          <Grid.Col span={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignmentBaseline: "middle",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{ display: "inline", width: "20px", height: "20px" }}
                hidden={uploadStatus.isUploading}
                onClick={() => removeMedia(m)}
              >
                <IconTrashX size="18px" color="red" />
              </Box>
              <Box ml="sm">{mediaPreview}</Box>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    );
  });

  // If the user is not allowed to edit then don't even render the modal
  if (!allowEdit) {
    return (
      <Box
        onClick={() => {
          if (!accessToken) {
            popMessage({
              color: "red",
              title: "Oh no!",
              message: "Must be logged in to edit memory!",
            });
          } else {
            popMessage({
              color: "red",
              title: "Oh no!",
              message: "Unable to edit memory. Please reload.",
            });
          }
        }}
      >
        {children}
      </Box>
    );
  }

  const modalEditTitle = selectedChildName
    ? `Edit Memory of ${selectedChildName}`
    : "Edit Memory";
  const modalNewTitle = selectedChildName
    ? `New Memory of ${selectedChildName}`
    : "New Memory";
  return (
    <>
      <Modal
        title={memory?.ID ? modalEditTitle : modalNewTitle}
        openButton={<Box sx={{ width: "100%" }}>{children}</Box>}
        modalControl={modalControl}
      >
        {showDelete && (
          <>
            <Box mt="md">
              <Text weight={600}>Are you sure?</Text>
              <Text mt="sm">
                This memory and media attached to it will become unavailable.
              </Text>
            </Box>

            <Box mt="xl">
              <Grid>
                <Grid.Col span={6}>
                  <Button onClick={() => setShowDelete(false)}>Go back</Button>
                </Grid.Col>
                <Grid.Col span={6} ta="right">
                  <DangerButton
                    leftIcon={<IconAlertTriangle />}
                    onClick={() => deleteMemoryData()}
                  >
                    Delete
                  </DangerButton>
                </Grid.Col>
              </Grid>
            </Box>
          </>
        )}

        {!showDelete && (
          <>
            <Grid mt="md">
              <Grid.Col span={12}>
                <Select
                  label="Child"
                  placeholder="Child"
                  data={
                    childList?.map((c) => ({
                      label: c.FirstName,
                      value: c.ID,
                    })) ?? []
                  }
                  value={memory.ChildID}
                  onChange={onSelectedChildUpdate}
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <Text weight={300}>Images / Video / Gif</Text>
                {mediaElements?.length > 0 && (
                  <Grid p="0" my="xs">
                    <Grid.Col span={12}>
                      <Box>{mediaElements}</Box>
                    </Grid.Col>
                  </Grid>
                )}
                {!uploadStatus.isUploading && (
                  <>
                    {!currentFile && (
                      <Box>
                        <FileButton
                          onChange={setCurrentFile}
                          accept="image/png,image/jpeg,image/gif,video/mp4,video/quicktime,image/heic"
                        >
                          {(props) => (
                            <Button
                              variant="outline"
                              sx={{ width: "100%" }}
                              {...props}
                            >
                              Tap to attach media
                            </Button>
                          )}
                        </FileButton>
                      </Box>
                    )}
                    {currentFile && (
                      <Box>
                        <Grid>
                          <Grid.Col span={12}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignmentBaseline: "middle",
                              }}
                            >
                              <Box sx={{ display: "inline" }}>
                                <DangerButton
                                  size="xs"
                                  variant="outline"
                                  onClick={() => setCurrentFile(null)}
                                >
                                  <IconTrashX size="18px" />
                                </DangerButton>
                              </Box>
                              <Box sx={{ display: "inline" }}>
                                <Text
                                  ml="sm"
                                  weight={300}
                                  sx={{
                                    lineHeight: "100%",
                                  }}
                                >
                                  {currentFile?.name}
                                </Text>
                              </Box>
                            </Box>
                          </Grid.Col>
                        </Grid>
                      </Box>
                    )}
                  </>
                )}
                <Grid.Col span={12} p="0" mt="sm">
                  <CalendarSelect
                    timestamp={new Date(memory.Timestamp)}
                    onTimestampChange={onTimestampChange}
                  />
                </Grid.Col>
                {exifData?.tags?.DateTimeOriginal && (
                  <Grid.Col span={12} p="0" sx={{ height: "10px" }} mt="4px">
                    <Text size="10px" weight={200}>
                      🕔 Date from media attachment
                    </Text>
                  </Grid.Col>
                )}

                <Grid.Col span={12} p="0" mt="sm">
                  <Textarea
                    required
                    id="message"
                    label="Message"
                    placeholder="Message ..."
                    // icon={<IconSignature />}
                    name="Message"
                    radius={theme.radius.md}
                    size="lg" // todo: pull this from theme
                    value={memory.Message}
                    autosize
                    minRows={2}
                    maxRows={4}
                    onChange={onMessageChange}
                  />
                </Grid.Col>
                <Grid.Col span={12} ta="right">
                  {isEnhancing ? (
                    <Box ta="right" sx={{ height: "30px", float: "right" }}>
                      <Spinner size="xs" />
                    </Box>
                  ) : (
                    <Button size="xs" onClick={getMessageWithEmoji}>
                      Enhance with emoji ✨😍
                    </Button>
                  )}
                </Grid.Col>

                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    display: uploadStatus.isUploading ? "block" : "none",
                    overflowX: "auto",
                  }}
                >
                  <Text variant="caption" sx={{ fontSize: "0.8rem" }}>
                    uploading...
                  </Text>
                  <Progress
                    value={uploadStatus.progress}
                    color="grape"
                    animate
                  />
                </Box>
              </Grid.Col>
            </Grid>
            <Grid mt="xl">
              <Grid.Col span={6}>
                {memory?.ID && !isSaving && !uploadStatus.isUploading && (
                  <DangerButton
                    leftIcon={<IconEraser />}
                    onClick={() => setShowDelete(true)}
                  >
                    Delete
                  </DangerButton>
                )}
              </Grid.Col>
              <Grid.Col span={6} ta="right">
                {isSaving && (
                  <Box sx={{ width: "100%" }} ta="center">
                    <Spinner size="xs" />
                  </Box>
                )}

                {!isSaving && memory?.ID && !uploadStatus.isUploading && (
                  <Button leftIcon={<IconNotebook />} onClick={saveMemoryData}>
                    Update
                  </Button>
                )}

                {!isSaving && !memory?.ID && (
                  <Button leftIcon={<IconNotebook />} onClick={saveMemoryData}>
                    Create
                  </Button>
                )}
              </Grid.Col>
            </Grid>
          </>
        )}
      </Modal>
    </>
  );
}

export default MemoryEdit;
