import { Box, Text, useMantineTheme } from "@mantine/core";
import React from "react";

import Logo from "./Logo";
import { LogoWithNameProps } from "./LogoWithName";

// import LogoSVG from "../../public/brand/logo.svg";

type LogoWithShortNameProps = LogoWithNameProps;

function LogoWithShortName({ sx, align }: LogoWithShortNameProps) {
  const theme = useMantineTheme();

  const containerHeight = 40;

  const logoHeight = containerHeight * 0.8;

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
        justifyContent: align || "center",
        height: `${containerHeight}px`,
      }}
      sx={sx}
    >
      <Logo logoHeight={logoHeight} />
      <Text
        ml="1px"
        size={`${containerHeight}px`}
        fw={100}
        color={theme.colors.gray[7]}
        style={{
          lineHeight: "100%",
          // I hate this random margin nudge....
          marginBottom: "-2px",
          flexGrow: 1,
        }}
      >
        minime
      </Text>
    </Box>
  );
}

export default LogoWithShortName;
