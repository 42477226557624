import { Box, Grid, GridProps, useMantineTheme } from "@mantine/core";
import { DateInput, DatePicker } from "@mantine/dates";
import {
  IconCalendar,
  IconHandFinger,
  IconKeyboardHide,
} from "@tabler/icons-react";
import React, { useState } from "react";

interface CalendarSelectProps extends Omit<GridProps, "children"> {
  timestamp: Date;
  onTimestampChange: (newDate: Date) => void;
}

function CalendarSelect({
  timestamp,
  onTimestampChange,
  ...others
}: CalendarSelectProps) {
  const theme = useMantineTheme();

  const [showCalendarSelect, setShowCalendarSelect] = useState(false);

  return (
    <Grid {...others}>
      <Grid.Col span={12}>
        <Grid>
          <Grid.Col span={9}>
            <DateInput
              size="xs"
              label="Date"
              onChange={onTimestampChange}
              radius={theme.radius.md}
              value={timestamp}
              maw={400}
              withAsterisk
              popoverProps={{ disabled: true }}
            />
          </Grid.Col>
          <Grid.Col
            span={3}
            onClick={() => setShowCalendarSelect(!showCalendarSelect)}
          >
            <Box sx={{ display: "flex", height: "100%" }} mt="6px">
              <Box
                sx={{
                  display: "inline-block",
                  alignSelf: "flex-end",
                }}
              >
                {showCalendarSelect ? (
                  <>
                    <Box
                      sx={{
                        display: "inline-block",
                      }}
                    >
                      <IconKeyboardHide stroke={1.5} color="red" />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "inline-block",
                      }}
                      mb="4px"
                    >
                      <IconCalendar stroke={1.5} color="purple" />
                    </Box>
                    <Box
                      sx={{
                        display: "inline-block",
                      }}
                      mb="4px"
                    >
                      <IconHandFinger stroke={1.5} color="purple" />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      {showCalendarSelect && (
        <Grid.Col span={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DatePicker
              firstDayOfWeek={0}
              onChange={onTimestampChange}
              value={timestamp}
            />
          </Box>
        </Grid.Col>
      )}
    </Grid>
  );
}

export default CalendarSelect;
