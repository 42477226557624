import { Context, useContext, useEffect } from "react";

import { Child } from "../../../../libs/ts/models/types";
import AuthContext, { AuthContextProps } from "../../contexts/Auth";
import ChildListContext, {
  ChildListContextProps,
} from "../../contexts/ChildList";

interface useChildListProps {
  context?: Context<AuthContextProps>;
  childListContext?: Context<ChildListContextProps>;
  children?: Child[];
}

export default function useChildList({
  context,
  childListContext,
}: useChildListProps = {}): Child[] {
  const { accessToken } = useContext(context ?? AuthContext);

  const { childList, loadChildList } = useContext(
    childListContext ?? ChildListContext
  );

  useEffect(() => {
    loadChildList();
  }, [accessToken]);

  return childList;
}
