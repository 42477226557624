import { Context, useContext, useEffect, useState } from "react";

import AuthContext, { AuthContextProps } from "../../contexts/Auth";
import accountUser from "../../models/accountUser";
import * as Api from "../ApiClient/Api";

export interface useAccountUsersProps {
  context?: Context<AuthContextProps>;
  users?: accountUser[];
}

export default function useAccountUsers({
  context = AuthContext,
  users,
}: useAccountUsersProps = {}): [
  accountUser[],
  () => Promise<void>,
  (userId: string) => Promise<void>
] {
  const { accessToken } = useContext(context);
  const [accountUsers, setAccountUsers] = useState<accountUser[]>(users);

  const loadAccountUsers = async () => {
    if (!accessToken) {
      return;
    }
    try {
      const { AccountUsers: aus } = await Api.getAccountUsers({ accessToken });
      setAccountUsers(aus);
    } catch (e) {
      console.log("unable to load account users", e);
    }
  };

  useEffect(() => {
    loadAccountUsers();
  }, [accessToken]);

  const refreshAccountUsers = async () => {
    setAccountUsers(null);
    await loadAccountUsers();
  };

  const removeAccountUser = async (userId: string) => {
    await Api.removeAccountUser({ accessToken, userId });
    await refreshAccountUsers();
  };

  return [accountUsers, refreshAccountUsers, removeAccountUser];
}
