import {
  EventOptions as GAEventOptions,
  trackEvent as gaTrackEvent,
} from "../GoogleAnalytics/GoogleAnalytics";
import { trackCustomEvent as redditTrackCustomEvent } from "../RedditPixel/RedditPixel";

export function sendEvent({ category, action, label, value }: GAEventOptions) {
  // Google Analytics
  gaTrackEvent({
    category,
    action,
    label,
    value,
  });

  // Reddit Pixel
  const redditEventName = [category, action, label].filter(Boolean).join(".");
  redditTrackCustomEvent(redditEventName);
}

export default {
  sendEvent,
};
