import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
} from "@mantine/core";
import React from "react";

import Tracking from "../Tracking/Tracking";

type SendEventOptions = {
  category: string;
  action: string;
  label?: string;
};

type ButtonProps = MantineButtonProps & {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClickSendEvent?: SendEventOptions;
};

function Button({ onClickSendEvent, onClick, ...others }: ButtonProps) {
  const handleClick = (e) => {
    try {
      if (onClickSendEvent) {
        Tracking.sendEvent(onClickSendEvent);
      }
    } catch (e) {
      console.error(e);
    }
    // call the original onClick handler
    onClick?.(e);
  };
  return (
    <MantineButton
      size="sm"
      variant="light"
      color="grape"
      onClick={handleClick}
      {...others}
    />
  );
}

export default Button;
