import axios from "axios";
import { Context, useContext, useEffect, useState } from "react";
import { ExifData, ExifParserFactory } from "ts-exif-parser";

import AuthContext, { AuthContextProps } from "../../contexts/Auth";
import * as Api from "../ApiClient/Api";

interface useFileUploadOptions {
  context?: Context<AuthContextProps>;
  file?: File;
}

export default function useFileUpload({
  context = AuthContext,
  file,
}: useFileUploadOptions): [
  File,
  (file: File) => void,
  (memoryId: string) => Promise<void>,
  { isUploading: boolean; progress: number },
  ExifData
] {
  const { accessToken } = useContext(context);

  const [currentFile, setCurrentFile] = useState<File>(file);
  const [uploadStatus, setUploadStatus] = useState({
    isUploading: false,
    progress: 0,
  });
  const [fileData, setFileData] = useState<ArrayBuffer>();
  const [exifData, setExifData] = useState<ExifData>();

  useEffect(() => {
    // clear any previous exif data
    setExifData(undefined);
    if (fileData) {
      const parsedData = ExifParserFactory.create(fileData).parse();
      setExifData(parsedData);
    }
  }, [fileData]);

  useEffect(() => {
    // clear any existing exif data
    setFileData(undefined);
    // when a new file is chosen, read the file data
    if (currentFile) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buff = e.target.result as ArrayBuffer;
        setFileData(buff);
      };
      reader.readAsArrayBuffer(currentFile);
    }
  }, [currentFile]);

  const onUploadProgress = (progress) => {
    const newProgress = (progress.loaded / progress.total) * 100;
    if (newProgress < 100) {
      setUploadStatus({
        ...uploadStatus,
        isUploading: true,
        progress: newProgress,
      });
    }
  };

  const sendUpload = async (memoryId: string, file: File, uploadConfig) => {
    setUploadStatus({
      isUploading: true,
      progress: 0,
    });
    // upload the file and report progress
    await axios.put(uploadConfig.URL, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress,
    });
    // attach the upload to the given memory
    await Api.attachUpload({
      accessToken,
      attachment: {
        MemoryID: memoryId,
        ContentType: currentFile.type,
        FileName: currentFile.name,
        Key: uploadConfig.Key,
      },
    });
    // clear the upload status and file handle
    setUploadStatus({
      ...uploadStatus,
      isUploading: false,
      progress: 0,
    });
    setCurrentFile(null);
  };

  const upload = async (memoryId: string) => {
    if (!currentFile) {
      // nothing to upload
      return;
    }
    const uploadConfig = await Api.getUploadUrl({
      accessToken,
      fileMeta: {
        FileName: currentFile.name,
        ContentType: currentFile.type,
      },
    });
    await sendUpload(memoryId, currentFile, uploadConfig);
  };

  return [currentFile, setCurrentFile, upload, uploadStatus, exifData];
}
