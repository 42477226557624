import Image, { ImageProps } from "next/image";
import Router from "next/router";
import React from "react";

import { PAGE_ROUTES } from "../../constants/Navigation";

const imageDimensions = {
  width: 1024,
  height: 1024,
};

interface LogoProps
  extends Omit<ImageProps, "alt" | "width" | "height" | "src"> {
  logoHeight: number;
}

function Logo({ logoHeight, ...others }: LogoProps) {
  const imageScale = logoHeight / imageDimensions.height;
  return (
    <Image
      priority
      alt="logo"
      width={`${Math.floor(imageDimensions.width * imageScale)}`}
      height={`${Math.floor(imageDimensions.height * imageScale)}`}
      src={"/brand/logo.png"}
      onClick={() => {
        Router.push(PAGE_ROUTES.LANDING);
      }}
      {...others}
    />
  );
}

export default Logo;
