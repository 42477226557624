import { Context, useContext, useEffect, useState } from "react";

import AuthContext, { AuthContextProps } from "../../contexts/Auth";
import account from "../../models/account";
import accountUser from "../../models/accountUser";
import * as Api from "../ApiClient/Api";

export const ProfileDefaults = {
  FirstName: "",
  LastName: "",
  Timezone: "",
} as const;

export default function useCurrentUser(
  context: Context<AuthContextProps> = AuthContext
): [accountUser, account] {
  const { accessToken } = useContext(context);
  const [currentUser, setCurrentUser] = useState<accountUser>();
  const [currentAccount, setCurrentAccount] = useState<account>();

  const loadCurrentUser = async () => {
    if (!accessToken) {
      return;
    }
    try {
      const { User: user, Account: acc } = await Api.getMe({ accessToken });

      // ensure the fields are not null
      user.FirstName = user.FirstName || ProfileDefaults.FirstName;
      user.LastName = user.LastName || ProfileDefaults.LastName;
      user.Timezone = user.Timezone || ProfileDefaults.Timezone;

      setCurrentUser(user);
      setCurrentAccount(acc);
    } catch (e) {
      setCurrentUser(undefined);
      setCurrentAccount(undefined);
    }
  };

  useEffect(() => {
    loadCurrentUser();
  }, [accessToken]);

  return [currentUser, currentAccount];
}
